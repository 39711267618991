<template>
<router-link
class="event-link"
 :to="{ name: 'EventDetails', params: { id: event.id } }">
  <div class="event-card">
    <!-- display event data -->
<span>@ {{event.time}} on {{event.date}} </span>
<h4>{{event.title}} </h4>
  </div>
  </router-link>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event:Object,
  },
};
</script>

<style scoped>
.event-card {
padding: 20px;
width: 250px;
cursor: pointer;
border: 1px solid;
margin-bottom: 18px;
}
.event-link {
  color: aqua;
  text-decoration: none;
}
</style>
